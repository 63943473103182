import styled from "styled-components"

import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core"

import { HomeGroupRole } from "src/data/homeGroups/types/homeGroupTypes"
import {
  HomeRole,
  TOrganizationRole,
} from "src/data/organizations/types/organizationMemberTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { BodyMixin } from "src/ui/MText"

export interface IRoleOption<RoleType> {
  label: string
  description: string
  role: RoleType
  hidden?: boolean
}

export interface IMemberRolePickerProps<RoleType> {
  disabled?: boolean
  required?: boolean
  value: RoleType | undefined
  onChange: (role: RoleType) => void | Promise<void>
  inline?: boolean
  options: IRoleOption<RoleType>[]
  roleFilter?: (role: RoleType) => boolean
}

export interface TOrgRoleOptions extends IRoleOption<TOrganizationRole> {}

export function MemberRolePicker<
  RoleType extends HomeRole | TOrganizationRole | HomeGroupRole,
>({
  value: role,
  disabled,
  required,
  inline,
  options,
  onChange,
  roleFilter = Boolean, // default to no filter
}: IMemberRolePickerProps<RoleType>) {
  const { _t, langKeys } = useTranslate()

  function renderValue(): string {
    if (!role) return ""
    return _t(options.find((o) => o.role === role)?.label ?? "")
  }

  function getMenuItems() {
    return options
      .filter((o) => roleFilter(o.role))
      .map((o) => {
        return (
          <StyledMenuItem key={o.role} value={o.role} hidden={o.hidden}>
            <ListItemText primary={_t(o.label)} secondary={_t(o.description)} />
          </StyledMenuItem>
        )
      })
  }

  return (
    <FormControl required={required}>
      {!inline && (
        <InputLabel htmlFor="role-select">
          {_t(langKeys.organizations_organization_role_title)}
        </InputLabel>
      )}
      <StyledSelect
        variant={!inline ? "outlined" : "standard"}
        id="role-select"
        value={role ?? ""}
        onChange={(e) => onChange(e.target.value as RoleType)}
        label={
          inline
            ? undefined
            : _t(langKeys.organizations_organization_role_title)
        }
        renderValue={() => renderValue()}
        disabled={disabled}
      >
        {getMenuItems()}
      </StyledSelect>
    </FormControl>
  )
}

const StyledSelect = styled(Select)`
  &.Mui-disabled::before,
  &::before {
    border-bottom: none;
    border-bottom-style: none;
  }
  ${BodyMixin};
`

const StyledMenuItem = styled(MenuItem)<{ hidden?: boolean }>`
  ${({ hidden }) => hidden && `display: none`};
`
